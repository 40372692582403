@media (min-width: 992px) {
  .nav-left,
  .nav-right {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
  }

  .nav-left {
    right: 50%;
    margin-right: 110px;
  }

  .nav-right {
    left: 50%;
    margin-left: 110px;
  }

  .middleBrand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    padding-top: 5px;
  }

  .navbar-collapse {
    height: 114px;
  }
}

@media (max-width: 991px) {
  .middleBrand {
    display: none;
  }
}
